@import 'colors';
@import 'responsive';

body,
p {
  color: $text-color-primary;
}

.text-mobile-3 h1,
.text-mobile-3.page-header {
  @include sm-down {
    font-size: 18px;
    line-height: 22px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 400;
  margin-bottom: 0;
  @include sm-down {
    &.text-mobile-0 {
      font-size: 10px;
      line-height: 12px;
    }

    &.mobile-caption {
      color: $text-color-secondary;
      font-size: 12px;
      line-height: 1;
    }

    &.text-mobile-1 {
      font-size: 14px;
      line-height: 17px;
    }

    &.text-mobile-2 {
      font-size: 16px;
      line-height: 1;
    }

    &.text-mobile-3 {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

h1,
.h1,
.text-mobile-3.page-header {
  //32px
  font-size: 2rem;
  line-height: 1;
}

h2,
.h2 {
  //24px
  font-size: 1.5rem;
  //26px
  line-height: 1.625rem;
}

h3,
.h3 {
  //18px
  font-size: 1.125rem;
  //22px
  line-height: 1.375rem;
}

h4,
.h4 {
  //16px
  font-size: 1rem;
}

.headline {
  font-weight: 700;
}

h5,
.h5,
.headline {
  //14px
  font-size: 0.875rem;
  //17px
  line-height: 1.0625rem;
  @include sm-down {
    &.mobile-caption {
      color: $text-color-secondary;
      font-size: 12px;
      line-height: 1;
    }
  }
}

h6,
.h6 {
  //10px
  font-size: 0.625rem;
  //12px
  line-height: 0.75rem;
}

.caption {
  //12px
  font-size: 0.75rem;
  line-height: 1;
}

// Button Text
button {
  &.medium,
  &.large {
    font-size: 1.125rem;
  }

  &.small {
    font-weight: 700;
    font-size: 0.75rem;
  }
}

@include sm-down {
  .mobile-caption {
    color: $text-color-secondary;
    font-size: 12px;
    line-height: 1;
  }
}

//Color Classes

.text-error {
  color: $text-color-error;
}

.text-link {
  color: $text-color-link;
}

.text-disabled {
  color: $text-color-disabled-2;
}