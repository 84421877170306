.icon_batteries,
.icon_compressed_gas,
.icon_corrosives,
.icon_hazardous_materials,
.icon_e_cigarettes,
.icon_fireworks,
.icon_household_items,
.icon_flammable {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.image_hazmat{
  background-image: url('../assets/dangerous-goods/image_hazmat.svg');
}
.icon_batteries {
  background-image: url('../assets/dangerous-goods/batteries.png');
}

.icon_compressed_gas {
  background-image: url('../assets/dangerous-goods/compressed_gas.png');
}

.icon_corrosives {
  background-image: url('../assets/dangerous-goods/corrosive.png');
}

.icon_e_cigarettes {
  background-image: url('../assets/dangerous-goods/e-cigarettes.png');
}

.icon_fireworks {
  background-image: url('../assets/dangerous-goods/fireworks.png');
}

.icon_flammable {
  background-image: url('../assets/dangerous-goods/flammable.png');
}

.icon_hazardous_materials {
  background-image: url('../assets/dangerous-goods/hazardous_materials.png');
}

.icon_household_items {
  background-image: url('../assets/dangerous-goods/household_items.png');
}

.dark-mode {
  .icon_household_items {
    background-image: url('../assets/dangerous-goods/household_items_dark.png');
  }
  .icon_hazardous_materials {
    background-image: url('../assets/dangerous-goods/hazardous_materials_dark.png');
  }
  .icon_flammable {
    background-image: url('../assets/dangerous-goods/flammable_dark.png');
  }
  .icon_fireworks {
    background-image: url('../assets/dangerous-goods/fireworks_dark.png');
  }
  .icon_e_cigarettes {
    background-image: url('../assets/dangerous-goods/e-cigarettes_dark.png');
  }
  .icon_corrosives {
    background-image: url('../assets/dangerous-goods/corrosive_dark.png');
  }
  .icon_compressed_gas {
    background-image: url('../assets/dangerous-goods/compressed_gas_dark.png');
  }
  .icon_batteries {
    background-image: url('../assets/dangerous-goods/batteries_dark.png');
  }
}
