.icon {
  &.icon-12 {
    font-size: 12px;
  }
  &.icon-14 {
    font-size: 14px;
  }

  &.icon-16 {
    font-size: 16px;
  }

  &.icon-17 {
    font-size: 17px;
  }

  &.icon-18 {
    font-size: 18px;
  }

  &.icon-20 {
    font-size: 20px;
  }

  &.icon-24 {
    font-size: 24px;
  }

  &.icon-30 {
    font-size: 30px;
  }

  &.icon-32 {
    font-size: 32px;
  }

  &.icon-40 {
    font-size: 40px;
  }

  &.icon-49 {
    font-size: 49px;
  }

  &.icon-56 {
    font-size: 56px;
  }

  &.icon-75 {
    font-size: 75px;
  }

  &.icon-text-primary {
    color: $text-color-primary;
  }

  &.icon-primary {
    color: $text-color-link;
  }

  &.icon-secondary {
    color: $text-color-link;
  }

  &.icon-tertiary {
    color: $text-color-accent;
  }

  &.icon-button-primary {
    color: $text-color-success;
  }

  &.icon-white {
    color: $text-color-tertiary;
  }

  &.icon-sun {
    color: $sun-text;
  }

  &.icon-disabled {
    color: $text-color-disabled;
  }

  &.icon-error {
    color: $text-color-error;
  }

  &.r-45 {
    transform: rotate(45deg);
  }

  &.r-90 {
    transform: rotate(90deg);
  }

  &.r-180 {
    transform: rotate(180deg);
  }

  &.r-270 {
    transform: rotate(270deg);
  }
}

/** Custom icons **/

.icon_plane {
  content: url('/assets/icons/icon_Book.svg');
}

.icon_Departure {
  content: url('/assets/icons/icon_Departure.svg');
}

.icon_Departure_white {
  content: url('/assets/icons/icon_Departure_white.svg');
}

.icon_Return {
  content: url('/assets/icons/icon_Return.svg');
}

.icon_Return_white {
  content: url('/assets/icons/icon_Return_white.svg');
}

//Booking Summary Custom Icons

.icon_bs-citilink {
  content: url('/assets/icons/booking-summary/icon_bs-citilink.svg');
}

.icon_bs-timer {
  content: url('/assets/icons/booking-summary/icon_bs-timer.svg');
}

.icon_bs-right-arrow {
  content: url('/assets/icons/booking-summary/icon_bs-right-arrow.svg');
}

.icon_bs-price-details-plane {
  content: url('/assets/icons/booking-summary/icon_bs-price-details-plane.svg');
}

.icon_bs-flight-details-line {
  content: url('/assets/icons/booking-summary/icon_bs-flight-details-line.svg');
}

.icon_add-baggage-bag {
  content: url('/assets/icons/icon_Add-Baggage-Bag.svg');
}

.icon_info-filled {
  content: url('/assets/icons/icon_info.svg');
}

.icon_seat-class {
  content: url('/assets/icons/icon_Seat_Class.svg');
}

.icon_restaurant-menu {
  content: url('/assets/icons/icon_Restaurant_menu.svg');
}

.icon_checked-bag {
  content: url('/assets/icons/icon_Checked_Bag.svg');
}

// payment logos
.logo_voucher {
  background: url('/assets/logo/logo_voucher.png') no-repeat;
  background-size: contain;
  background-position: center;
}

.logo_shopeepay {
  background: url('/assets/logo/logo_shopeepay.png') no-repeat;
  background-size: contain;
  background-position: center;
}

.logo_linkaja {
  background: url('/assets/logo/logo_linkaja.png') no-repeat;
  background-size: contain;
  background-position: center;
}

.logo_ovo {
  background: url('/assets/logo/logo_ovo.jpg') no-repeat;
  background-size: contain;
  background-position: center;
}

.logo_indodana {
  background: url('/assets/logo/logo_indodana.png') no-repeat;
  background-size: contain;
  background-position: center;
}

.logo_bca {
  background: url('/assets/logo/logo_bca.png') no-repeat;
  background-size: contain;
  background-position: center;
}

.logo_indomaret {
  background: url('/assets/logo/logo_indomaret.png') no-repeat;
  background-size: contain;
  background-position: center;
}

.logo_alfamart {
  background: url('/assets/logo/logo_alfamart.png') no-repeat;
  background-size: contain;
  background-position: center;
}

.logo_alfamidi {
  background: url('/assets/logo/logo_alfamidi.png') no-repeat;
  background-size: contain;
  background-position: center;
}

.logo_bni {
  background: url('/assets/logo/logo_bni.png') no-repeat;
  background-size: contain;
  background-position: center;
}

.logo_bri {
  background: url('/assets/logo/logo_bri.png') no-repeat;
  background-size: contain;
  background-position: center;
}

.logo_bca {
  background: url('/assets/logo/logo_bca.png') no-repeat;
  background-size: contain;
  background-position: center;
}

.logo_mandiri {
  background: url('/assets/logo/logo_mandiri.png') no-repeat;
  background-size: contain;
  background-position: center;
}

.logo_permata {
  background: url('/assets/logo/logo_permata.png') no-repeat;
  background-size: contain;
  background-position: center;
}

.logo_credit_card {
  background: url('/assets/logo/logo_credit_card.png') no-repeat;
  background-size: contain;
  background-position: center;
}

.icon_trip-plane-icon-filled {
  content: url("/assets/icons/icon_trip-flight-plane-icon.svg");
}
.icon_trip-line-left {
  content: url("/assets/icons/icon_trip-line-left.svg");
}
.icon_trip-line-right {
  content: url("/assets/icons/icon_trip-line-right.svg");
}
.icon_Panel-ticket {
  content: url("/assets/icons/icon_Panel-ticket.svg");
}
.icon_Panel-Refund {
  content: url("/assets/icons/icon_Panel-Refund.svg");
}
.icon_Boarding-Pass-button {
  content: url("/assets/icons/icon_Boarding_Pass_icon_button.svg");
}
.icon_seat-greenzone {
  content: url("/assets/icons/seats-page/icon_seat-greenzone.svg");
}

.icon_seat-selected {
  content: url("/assets/icons/seats-page/icon_seat-selected.svg");
}

.icon_seat-unavailable {
  content: url("/assets/icons/seats-page/icon_seat-unavailable.svg");
}

.icon_Verified {
  content: url("/assets/icons/icon_Verified.svg");
}

.icon_adult-icon {
  content: url("/assets/icons/icon_Adult-icon.svg");
}
.icon_child-icon {
  content: url("/assets/icons/icon_Child-icon.svg");
}

.icon_infant-icon {
  content: url("/assets/icons/icon_Infant-icon.svg");
}

.icon_reminder {
  content: url("/assets/icons/icon_Reminder.svg");
}

.icon_backward {
  content: url("/assets/icons/icon_Backward.svg");
}

.icon_calendar_departure {
  content: url("/assets/icons/icon_Calendar_Depart.svg");
}

.icon_calendar_departure_disabled {
  content: url("/assets/icons/icon_Calendar_Return.svg");
  opacity: 0.3;
}

.icon_calendar_return {
  content: url("/assets/icons/icon_Calendar_Return.svg");
}

.icon_calendar_return_disabled {
  content: url("/assets/icons/icon_Calendar_Return.svg");
  opacity: 0.3;
}

.icon_ticket_outline {
  content: url("/assets/icons/icon_Ticket_outline.svg");
}

$meals: BDCA, BDSE, BDKU, CRSD, FRCS, FTPR, MGJA, MOPG, NABU, NAWU, NBIG, NBMI, NGRS, NKDH, NRCK, NSSS, OMCS, SPCA, SSCC, TMCE, VNLA, BGAA, BGAK, BGAR, BGAS, BGBA, BGCK, BGCR, BGCS, BGLA, BGPK, BGPR, BGPS, FRAL, FRCL, FRPL, NICS, SOMY, RJBU, PSPS, OTKK, CSVG, NMAK, CGBL, BDSB, BDKG, NLAP;
@each $meal in $meals {
  .image-#{$meal} {
    background-image: url('../assets/meals/#{$meal}.png');
  }
}

.icon_booking-copy {
  content: url('/assets/icons/itinerary/icon_booking-copy.svg');  
}

.icon_add-ssrs {
  content: url('/assets/icons/itinerary/icon_add-ssrs.svg');
}

.icon_add-payment {
  width: 20px;
  content: url('/assets/icons/itinerary/icon_add-payment.svg');
}

.icon_itinerary-checkin {
  content: url('/assets/icons/itinerary/icon_itinerary-checkin.svg');
}

.icon_ticket {
  content: url('/assets/icons/Ticket_Iconly.svg');
}
