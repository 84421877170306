@import '@angular/cdk/overlay-prebuilt';
@import 'responsive';
@import 'colors';
@import 'elevations';

.cdk-overlay-container {
  @include zindex-popover;

  .popup-backdrop {
    background-color: $background-overlay;
  }

  .popup {
    @include sm-down {
      .container {
        align-self: initial;
      }

      .button-footer {
        display: initial;
        justify-content: initial;
      }

      .navitaire-digital-button {
        width: 100%;
      }

      .sm-fixed-bottom {
        bottom: 0;
        position: fixed;
        z-index: $zindex-fixed;
      }
    }

    .container {
      align-self: center;
    }

    .button-footer {
      display: flex;
      justify-content: center;
    }
  }

  .fare-select-popup {
    margin: auto !important;
  }

  .modal-small {
    @include md-up {
      max-width: 490px;
    }
  }

  .toast-display {
    position: absolute !important;
    right: 24px;
    top: 15px;

    @include sm-down {
      left: 5%;
      right: 5%;
    }
  }

  .mobile-backdrop {
    background-color: $color-1;
  }

  .mobile {
    overflow-y: scroll;

    .selected-dates-container {
      background-color: $color-4;
      color: $text-color-tertiary;
      padding-top: 12px;
    }

    .scroll {
      overflow: scroll;
      padding-bottom: 30%;
    }

    .full-screen-header {
      background-color: $color-4;
      color: $text-color-tertiary;
    }

    .footer {
      background-color: $color-12;
    }

    .tab-header {
      line-height: 17px;
      margin: 0 10px;
      padding: 7px;
      text-align: center;
      width: 100%;

      .label {
        font-size: 0.75rem;
        line-height: 15px;
      }

      &.active {
        background-color: $color-10;
        border-radius: 4px 4px 0 0;
        color: $text-color-primary;

        .label {
          color: $text-color-disabled;
        }
      }
    }
  }

  .container {
    @include container;
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }

  .footer {
    bottom: 0;
    left: 0;
    padding: $p2;
    position: fixed;
    right: 0;

    button {
      padding: $p3;
      width: 100%;
    }
  }
}
