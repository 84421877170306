@import 'buttons';

button {
  cursor: pointer;
  font-size: inherit;

  &:disabled {
    cursor: not-allowed;
  }

  &.navitaire-digital-fab {
    @include navitaire-digital-fab;
  }

  &.navitaire-digital-button {
    @include navitaire-digital-button;
  }

  &.navitaire-digital-button-link {
    @include navitaire-digital-button-link;
  }

  &.navitaire-digital-button-link-white {
    @include navitaire-digital-button-link-white;
  }

  // Sizing
  &.small {
    @include navitaire-digital-button-small;
  }

  &.medium {
    @include navitaire-digital-button-medium;
  }

  &.large {
    @include navitaire-digital-button-large;
  }
  &.panel-button {
    @include navitaire-digital-panel-button;
  }

  // Button types

  &.primary {
    @include navitaire-digital-button-primary;
  }

  &.secondary {
    @include navitaire-digital-button-secondary;
  }

  &.secondary-transparent {
    @include navitaire-digital-button-secondary-transparent;
  }

  &.tertiary {
    @include navitaire-digital-button-tertiary;
  }

  &.quaternary {
    @include navitaire-digital-button-quaternary;
  }

  &.quinary {
    @include navitaire-digital-button-quinary;
  }

  &.dark {
    @include navitaire-digital-button-dark;
  }

  &.light {
    @include navitaire-digital-button-light;
  }

  &.transparent {
    @include navitaire-digital-button-transparent;
  }

  &.success {
    @include navitaire-digital-button-success;
  }

  &.primary-transparent {
    @include navitaire-digital-button-primary-transparent;
  }

  &.outlined-success {
    @include button-outlined-success;
  }

  &.borderless {
    @include navitaire-digital-button-borderless;
  }

  &.cancel {
    @include button-outlined-cancel;
  }

  &.cancel-link {
    @include button-cancel-link;
  }
}
