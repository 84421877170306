/* stylelint-disable */

//Primary for Backgrounds
$color-0: var(--color-0);
$color-1: var(--color-1);
$color-2: var(--color-2);
$color-3: var(--color-3);
$color-4: var(--color-4);
$color-5: var(--color-5);
$color-6: var(--color-6);
$color-7: var(--color-7);
$color-8: var(--color-8);
$color-9: var(--color-9);
$color-10: var(--color-10);
$color-11: var(--color-11);
$color-12: var(--color-12);
$color-13: var(--color-13);
$color-14: var(--color-14);
$color-15: var(--color-15);
$color-16: var(--color-16);
$color-17: var(--color-17);
$color-18: var(--color-18);
$color-19: var(--color-19);
$color-20: var(--color-20);
$color-21: var(--color-21);
$color-22: var(--color-22);
$color-23: var(--color-23);
$color-24: var(--color-24);

//Accent Colors
$accent-1: var(--accent-1);
$accent-2: var(--accent-2);

//Text & Icon Colors
$text-color-primary: var(--text-color-primary);
$text-color-secondary: var(--text-color-secondary);
$text-color-tertiary: var(--text-color-tertiary);
$text-color-4: var(--text-color-4);
$text-color-5: var(--text-color-5);
$text-color-success: var(--text-color-success);
$text-color-warning: var(--text-color-warning);
$text-color-error: var(--text-color-error);
$text-color-disabled: var(--text-color-disabled);
$text-color-disabled-2: var(--text-color-disabled-2);
$text-color-link: var(--text-color-link);
$text-color-accent: var(--text-color-accent);
$text-color-link-2: var(--text-color-link-2);
$text-color-title: var(--text-color-title);
$text-color-input: var(--text-color-input);
$text-color-title-light: var(--text-color-title-light);
$text-color-placeholder-2: var( --text-color-palceholder-2);
$text-color-supporting-text: var(--text-color-supporting-text);
$text-color-primary-green-2: var(--text-color-primary-green-2);
$text-color-primary-green-3: var(--text-color-primary-green-3);

//Button Colors
$button-primary: var(--button-primary);
$button-secondary: var(--button-secondary);
$button-tertiary: var(--button-tertiary);
$button-cancel: var(--button-cancel);
$button-dark: var(--button-dark);
$button-disabled: var(--button-disabled);
$button-disabled-2: var(--button-disabled-2);
$button-disabled-3: var(--button-disabled-3);
$button-toggle-1: var(--button-toggle-1);
$button-toggle-2: var(--button-toggle-2);
$button-selected: var(--button-selected);
$button-link-text: var(--button-link-text);
$button-text-primary: var(--button-text-primary);
$button-text-secondary: var(--button-text-secondary);
$button-text-tertiary: var(--button-text-tertiary);

//Seats
$text-normal-seat: var(--text-normal-seat);
$seat-unavailable: var(--seat-unavailable);
$seat-default: var(--seat-default);
$seat-business: var(--seat-business);
$seat-legroom: var(--seat-legroom);
$seat-normal: var(--seat-normal);
$seat-speedy-exit: var(--seat-speedy-exit);
$seat-standard-plus: var(--seat-standard-plus);
$seat-selected: var(--seat-selected);
$seat-preferred: var(--seat-preferred);

// Borders
$border-1: var(--border-1);
$border-2: var(--border-2);
$border-3: var(--border-3);
$border-4: var(--border-4);
$border-5: var(--border-5);
$border-6: var(--border-6);
$border-7: var(--border-7);
$border-8: var(--border-8);
$border-9: var(--border-9);
$border-10: var(--border-10);
$border-11: var(--border-11);
$border-12: var(--border-12);
$border-13: var(--border-13);
$border-14: var(--border-14);
$border-15: var(--border-15);
$border-16: var(--border-16);

// Gradients
$color-gradient-1: var(--color-gradient-1);
$color-gradient-2: var(--color-gradient-2);
$color-gradient-3: var(--color-gradient-3);
$color-gradient-4: var(--color-gradient-4);
$color-gradient-5: var(--color-gradient-5);
$color-gradient-6: var(--color-gradient-6);
$color-gradient-7: var(--color-gradient-7);
$color-gradient-8: var(--color-gradient-8);

// Background
$background-overlay: var(--background-overlay);
$background-1: var(--background-1);
$background-2: var(--background-2);
$background-3: var(--background-3);
$background-4: var(--background-4);
$background-5: var(--background-5);
$background-6: var(--background-6);
$background-7: var(--background-7);
$background-8: var(--background-8);
$background-9: var(--background-9);
$background-error: var(--background-error);

//Plane background
$left-wing: var(--left-wing);
$right-wing: var(--right-wing);
$tail: var(--tail);
$plane-nose: var(--plane-nose);

  //Calendar colors
  $sun-text: var(--sun-text);
  $weekdays-text: var(--weekdays-text);
  $days-highlight: var(--days-highlight);

/* stylelint-enable */
