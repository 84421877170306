@mixin navitaire-digital-fab {
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
  display: flex;
  height: 70px;
  justify-content: center;
  margin: 0;
  outline: none;
  padding: 0;
  transition: all 0.2s;
  width: 70px;
}

@mixin navitaire-digital-button {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  outline: none;
  padding: 0;
  transition: all 0.2s;
  white-space: nowrap;
}

@mixin navitaire-digital-button-small {
  height: 36px;
  padding: 0 1.5rem;
}

@mixin navitaire-digital-button-medium {
  height: 48px;
  padding: 0 1.5rem;
}

@mixin navitaire-digital-button-large {
  height: 48px;
  padding: 0 5.5rem;
}

@mixin navitaire-digital-panel-button {
  height: 2.25rem;
  padding: 0.875rem 2.75rem 0.8125rem 2.75rem;
  gap: 0.5rem;
}

@mixin navitaire-digital-button-primary {
  background-color: $button-primary;
  color: $button-text-primary;

  &:disabled {
    background-color: $button-disabled;
    color: $button-text-primary;
  }

  &:focus,
  &:hover:enabled {
    opacity: 0.94;
  }
}

@mixin navitaire-digital-button-secondary {
  background-color: $button-secondary;
  color: $button-text-primary;

  &:disabled {
    background-color: $button-disabled;
    color: $button-text-primary;
  }

  &:focus,
  &:hover:enabled {
    opacity: 0.94;
  }
}

@mixin navitaire-digital-button-secondary-transparent {
  align-items: center;
  background-color: transparent;
  border: 1px solid $button-secondary;
  color: $button-secondary;
  display: flex;
  justify-content: center;

  &:disabled {
    background-color: $button-disabled;
    color: $button-text-primary;
  }

  &:focus,
  &:hover:enabled {
    background-color: $button-secondary;
    color: $button-text-primary;
  }
}

@mixin navitaire-digital-button-tertiary {
  background-color: $button-tertiary;
  border: 1px solid $button-primary;
  color: $button-primary;

  &:disabled {
    background-color: $button-disabled;
    color: $button-text-primary;
  }

  &:focus,
  &:hover:enabled {
    opacity: 0.88;
  }
}
@mixin navitaire-digital-button-quaternary {
  background-color: $button-tertiary;
  border: 1px solid $button-secondary;
  color: $button-secondary;

  &:disabled {
    background-color: $button-disabled;
    color: $button-text-primary;
  }

  &:focus,
  &:hover:enabled {
    opacity: 0.88;
  }
}
@mixin navitaire-digital-button-quinary {
  background-color: $button-secondary;
  opacity: 0.88;
  border: 1px solid $button-secondary !important;
  color: $button-text-primary;

  &:disabled {
    background-color: $button-secondary;
    opacity: 0.88;
    border-color: $button-disabled;
    color: $button-text-primary;
  }

  &:focus,
  &:hover:enabled {
    opacity: 0.76;
  }
}

@mixin navitaire-digital-button-light {
  background-color: $button-secondary;
  border: 1px solid $button-tertiary;
  color: $button-text-primary;

  &:disabled {
    border-color: $button-disabled;
    color: $button-disabled;
  }

  &:focus,
  &:hover:enabled {
    opacity: 0.88;
  }
}
@mixin navitaire-digital-button-transparent {
  background-color: transparent;
  border: 1px solid $button-tertiary;
  color: $button-text-primary;

  &:disabled {
    background-color: transparent;
    border-color: $button-disabled;
    color: $button-disabled;
  }

  &:focus,
  &:hover:enabled {
    background-color: $button-secondary;
    color: $button-text-primary;
  }
}

@mixin navitaire-digital-button-dark {
  background-color: $button-dark;
  border: none;
  color: $button-text-primary;

  &:disabled {
    border-color: $button-dark;
    color: $button-text-primary;
  }

  &:focus,
  &:hover:enabled {
    opacity: 0.91;
  }
}

@mixin navitaire-digital-button-success {
  background-color: $button-primary;
  border: 1px solid $button-tertiary;
  color: $button-text-primary;

  &:focus,
  &:hover:enabled {
    opacity: 0.94;
  }

  &:disabled {
    background-color: $button-disabled;
    color: $button-text-primary;
  }
}

@mixin navitaire-digital-button-primary-transparent {
  border: 1px solid $border-4;
  color: $color-4;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-transform: capitalize;
}

@mixin navitaire-digital-button-borderless {
  border: 0;
}

@mixin navitaire-digital-button-link {
  @include navitaire-digital-button-borderless();
  background-color: transparent;
  color: $text-color-link;

  &:hover {
    text-decoration: underline;
  }
}

@mixin navitaire-digital-button-link-white {
  @include navitaire-digital-button-borderless();
  background-color: transparent;
  color: $button-text-primary;

  &:hover {
    text-decoration: underline;
  }
}

@mixin button-outlined-cancel {
  background-color: transparent;
  border: 1px solid $button-cancel;
  border-radius: 2px;
  color: $button-cancel;

  &:hover:enabled {
    opacity: 0.94;
  }
}

@mixin button-outlined-success {
  background-color: $button-tertiary;
  border: 1px solid $button-primary;
  border-radius: 2px;
  color: $button-primary;

  &:hover:enabled {
    opacity: 0.94;
  }
}

@mixin button-cancel-link {
  background-color: $button-tertiary;
  border: none;
  color: $button-cancel;

  &:hover:enabled {
    font-weight: bold;
  }
}
