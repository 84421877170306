@import 'colors';
@import 'styles/icon-font.scss';
@import 'styles/index.scss';

.default-theme {
  a {
    color: $text-color-link;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  button,
  input {
    line-height: inherit;
  }
  input {
    border-radius: inherit;
    background-color: --background-6;
    
  }

  b,
  strong {
    font-weight: bolder;
  }

  .company-logo {
    background-image: url('./assets/logo/citilink_logo_full.svg');
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    height: 40px;
    width: 161px;

    &.icon-availability-journey {
      width: 64px;
      height: 16px;
    }

    @include md-only {
      background-image: url('./assets/logo/citilink_logo_full.svg') !important;
      height: 48px;
      width: 48px;
    }

    @include sm-down {
      background-image: url('./assets/logo/citilink_logo_full.svg');
      height: 38px;
      width: 151px;
    }
  }

  .left .company-logo {
    @include sm-down {
      background-image: url('./assets/logo/citilink_logo_full.svg') !important;
      height: 48px;
      margin-left: $p4;
      width: 48px;
    }
  }
}

.dark-theme {
  .company-logo {
    background-image: url('./assets/logo/navitaire_logo_white.png');
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    height: 38px;
    width: 122px;

    @include md-only {
      background-image: url('./assets/logo/navitaire_logo_small_white.svg') !important;
      height: 48px;
      width: 48px;
    }

    @include sm-down {
      background-image: url('./assets/logo/navitaire_logo_white.png');
      height: 38px;
      width: 122px;
    }
  }

  .left .company-logo {
    @include sm-down {
      background-image: url('./assets/logo/navitaire_logo_small_white.svg') !important;
      height: 48px;
      margin-left: $p4;
      width: 48px;
    }
  }
}

html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
}

html,
body {
  height: 100%;
}

body {
  line-height: 1.5;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  font-family: 'Lato', sans-serif;
  font-display: swap;
}

.cdk-overlay-container {
  z-index: 9999;
}

#version-footer {
  border-top: 1px solid white;
  padding: 0 20px;
}
