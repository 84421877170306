@font-face {
  font-family: 'Navitaire_icon_font';
  src: url('../assets/fonts/Navitaire_icon_font.eot?gxkwgz');
  src: url('../assets/fonts/Navitaire_icon_font.eot?gxkwgz#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Navitaire_icon_font.ttf?gxkwgz') format('truetype'),
    url('../assets/fonts/Navitaire_icon_font.woff?gxkwgz') format('woff'),
    url('../assets/fonts/Navitaire_icon_font.svg?gxkwgz#Navitaire_icon_font')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon_'],
[class*=' icon_'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Navitaire_icon_font' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::before,
  &::after {
    font-family: 'Navitaire_icon_font' !important;
  }
}

.icon_cloud_download_white:before {
  content: '\e900';
}
.icon_filter:before {
  content: '\e901';
}
.icon_apple_wallet .path1:before {
  content: '\e902';
  color: rgb(54, 153, 202);
}
.icon_apple_wallet .path2:before {
  content: '\e903';
  margin-left: -1.2919921875em;
  color: rgb(251, 173, 24);
}
.icon_apple_wallet .path3:before {
  content: '\e904';
  margin-left: -1.2919921875em;
  color: rgb(80, 185, 71);
}
.icon_apple_wallet .path4:before {
  content: '\e905';
  margin-left: -1.2919921875em;
  color: rgb(243, 108, 95);
}
.icon_apple_wallet .path5:before {
  content: '\e906';
  margin-left: -1.2919921875em;
  color: rgb(216, 216, 216);
}
.icon_child:before {
  content: '\e907';
}
.icon_adult:before {
  content: '\e908';
}
.icon_account:before {
  content: '\e909';
}
.icon_add:before {
  content: '\e90a';
}
.icon_add_button:before {
  content: '\e90b';
}
.icon_address_button:before {
  content: '\e90c';
}
.icon_bag_button:before {
  content: '\e90d';
}
.icon_boarding_pass:before {
  content: '\e90e';
}
.icon_book_flight_button:before {
  content: '\e90f';
}
.icon_book_flight_solid:before {
  content: '\e910';
}
.icon_business:before {
  content: '\e911';
}
.icon_cart_button:before {
  content: '\e912';
}
.icon_chat_bubble:before {
  content: '\e913';
}
.icon_check_button:before {
  content: '\e914';
}
.icon_check_only:before {
  content: '\e915';
}
.icon_chevron_right:before {
  content: '\e917';
}
.icon_number_control-_neg:before {
  content: '\e918';
}
.icon_close:before {
  content: '\e919';
}
.icon_close_small_button:before {
  content: '\e91a';
}
.icon_deals_solid:before {
  content: '\e91b';
}
.icon_document_button:before {
  content: '\e91c';
}
.icon_email:before {
  content: '\e91d';
}
.icon_exclamation_button:before {
  content: '\e91e';
}
.icon_exit_triangle:before {
  content: '\e91f';
}
.icon_facebook_button:before {
  content: '\e920';
}
.icon_galley:before {
  content: '\e921';
}
.icon_hide_password:before {
  content: '\e922';
}
.icon_home_solid:before {
  content: '\e923';
}
.icon_info_button:before {
  content: '\e924';
}
.icon_instagram_button:before {
  content: '\e925';
}
.icon_itinerary_button:before {
  content: '\e926';
}
.icon_location_solid:before {
  content: '\e927';
}
.icon_lock_button:before {
  content: '\e928';
}
.icon_luggage_carry_on:before {
  content: '\e929';
}
.icon_luggage_checked_bags:before {
  content: '\e92a';
}
.icon_meal_button:before {
  content: '\e92b';
}
.icon_more:before {
  content: '\e92c';
}
.icon_my_location:before {
  content: '\e92d';
}
.icon_navitaire_back:before {
  content: '\e92e';
}
.icon_next_button:before {
  content: '\e92f';
}
.icon_notifications:before {
  content: '\e930';
}
.icon_passport:before {
  content: '\e931';
}
.icon_phone:before {
  content: '\e932';
}
.icon_photo_camera:before {
  content: '\e933';
}
.icon_reset_button:before {
  content: '\e934';
}
.icon_restroom:before {
  content: '\e935';
}
.icon_search:before {
  content: '\e936';
}
.icon_seat_button:before {
  content: '\e937';
}
.icon_seat_price_swatch:before {
  content: '\e938';
}
.icon_show_password:before {
  content: '\e939';
}
.icon_status_button:before {
  content: '\e93a';
}
.icon_status_solid_button:before {
  content: '\e93b';
}
.icon_twitter_button:before {
  content: '\e93c';
}
.icon_unchecked_button:before {
  content: '\e93d';
}
.icon_user_profile:before {
  content: '\e93e';
}
.icon_wallet_button:before {
  content: '\e93f';
}
.icon_warning:before {
  content: '\e940';
}
.icon_warning_button:before {
  content: '\e941';
}
.icon_website:before {
  content: '\e942';
}
.icon_youtube_button:before {
  content: '\e943';
}
.icon_navitaire_logo:before {
  content: '\e944';
}
.icon_remove:before {
  content: '\e945';
}
.icon_book_flight_circle_rotated:before {
  content: '\e946';
}
.icon_directions_car:before {
  content: '\e947';
}
.icon_hotel:before {
  content: '\e948';
}
.icon_beach_access:before {
  content: '\e949';
}
.icon_local_activity:before {
  content: '\e94a';
}
.icon_arrow_down:before {
  content: '\e94b';
}
.icon_arrow_back:before {
  content: '\e94c';
}
.icon_compare_arrows:before {
  content: '\e94d';
}
.icon_Edit_underline:before {
  content: '\e94e';
}
.icon_copy:before {
  content: '\e916';
}
.icon_flight_takeoff:before {
  content: '\e94f';
}
.icon_number_control_pos:before {
  content: '\e950';
}
.icon_departure_date:before {
  content: '\e951';
}
.icon_passenger:before {
  content: '\e952';
}
.icon_return_date:before {
  content: '\e953';
}
.icon_kosher:before {
  content: '\e954';
}
.icon_gluten_free:before {
  content: '\e955';
}
.icon_flight_return:before {
  content: '\e956';
}
.icon_seat:before {
  content: '\e957';
}
.icon_no_flight:before {
  content: '\e958';
}
.icon_no_seats:before {
  content: '\e959';
}
.icon_sort:before {
  content: '\e95a';
}
.icon_chatbot:before {
  content: '\e95b';
}
.icon_priority_boarding:before {
  content: '\e95c';
}
.icon_meal:before {
  content: '\e95d';
}
.icon_wifi-off:before {
  content: '\e95e';
}
.icon_wifi-on:before {
  content: '\e95f';
}
.icon_Delete:before {
  content: '\e960';
}
.icon_close_dialog:before {
  content: '\e961';
}
.icon_train_depart:before {
  content: '\e962';
}
.icon_train_return:before {
  content: '\e963';
}
.icon_book_train:before {
  content: '\e964';
}
.icon_no_train:before {
  content: '\e965';
}
.icon_arrow_right_alt:before {
  content: '\e966';
}
.icon_beach_access_button:before {
  content: '\e967';
}
.icon_hotel_button:before {
  content: '\e968';
}
.icon_star_full:before {
  content: '\e969';
}
.icon_star_half:before {
  content: '\e96a';
}
.icon_star_empty:before {
  content: '\e96b';
}
.icon_printer:before {
  content: '\e96c';
}
.icon_refresh_loop:before {
  content: '\ea2e';
}
.icon_chevron_left:before {
  content: '\e96d';
}
.icon_chevron_left_sm:before {
  content: '\e96e';
}
.icon_info_fill:before {
  content: '\e96f';
}

.icon_pay_now:before {
  content: '\e970';
  color: #5ca137;
}
.icon_pay_later:before {
  content: '\e971';
  color: #ffc107;
}

.icon_circle_check_green:before {
  content: '\e915';
  color: #5ca137;
  background-color: rgb(92 161 55 / 20%);
  border-radius: 50%;
  border: 3px solid hsl(0deg 0% 100% / 20%);
}

.icon_chevron_up:before {
  content: '\e972';
}

.icon_chevron_down:before {
  content: '\e973';
}
