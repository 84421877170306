/* stylelint-disable */
.dark-theme {
  //Primary for Backgrounds
  --color-0: #000000;
  --color-1: #011c30;
  --color-2: #001321;
  --color-3: #001321;
  --color-4: #012f50;
  --color-5: #012f50;
  --color-6: #012f50;
  --color-7: #f28500;
  --color-8: #dc5d66;
  --color-9: rgba(0, 0, 0, 0.38);
  --color-10: #154162;
  --color-11: #000;
  --color-12: #011c30;
  --color-13: #154162;
  --color-14: #011c30;
  --color-15: #fff;
  //Accent Colors
  --accent-1: #bd10e0;

  //Text & Icon Colors
  --text-color-primary: rgba(255, 255, 255, 0.87);
  --text-color-secondary: rgba(255, 255, 255, 0.6);
  --text-color-tertiary: #fff;
  --text-color-4: rgba(255, 255, 255, 0.38);
  --text-color-5: rgba(255, 255, 255, 0.8);
  --text-color-success: #5ca137;
  --text-color-warning: #f28500;
  --text-color-error: #dc5d66;
  --text-color-disabled: #a7a7a7;
  --text-color-disabled-2: #4a4a4a;
  --text-color-link: #fff;
  --text-color-accent: #bd10e0;
  --text-color-link-2: #0069b3;

  //Button Colors
  --button-primary: #5ca137;
  --button-secondary: #0069b3;
  --button-tertiary: #fff;
  --button-cancel: #dc5d66;
  --button-dark: #012f50;
  --button-disabled: #a7a7a7;
  --button-toggle-1: rgba(0, 0, 0, 0.3);
  --button-toggle-2: rgba(0, 0, 0, 0.1);
  --button-selected: #154162;
  --button-link-text: rgba(255, 255, 255, 0.87);
  --button-text-primary: #fff;
  --button-text-secondary: rgba(255, 255, 255, 0.87);
  --button-text-tertiary: rgba(255, 255, 255, 0.6);

  //Seats
  --text-normal-seat: #012f50;
  --seat-unavailable: #001321;
  --seat-default: #012f50;
  --seat-business: #5ca137;
  --seat-legroom: #f28500;
  --seat-normal: #0069b3;
  --seat-speedy-exit: #0069b3;
  --seat-standard-plus: #bd10e0;
  --seat-selected: #dc5d66;
  --seat-preferred: #bdd6f4;

  // Borders
  --border-1: rgba(0, 0, 0, 0.2);
  --border-2: rgba(255, 255, 255, 0.2);
  --border-3: rgba(227, 234, 240, 0.2);
  --border-4: #0069b3;
  --border-5: #fff;
  --border-6: #dc5d66;
  --border-7: #bd10e0;
  --border-8: rgba(255, 255, 255, 0.2);
  --border-9: rgba(0, 0, 0, 0.1);
  --border-10: rgba(0, 0, 0, 0.08);

  // Gradients
  --color-gradient-1: linear-gradient(0deg, #012f50 0%, #012f50 100%);
  --color-gradient-2: linear-gradient(90deg, #012f50 0%, #bd10e0 100%);
  --color-gradient-3: linear-gradient(90deg, #012f50 0%, #f28500 100%);
  --color-gradient-4: linear-gradient(90deg, #012f50 0%, #154162 100%);
  --color-gradient-5: linear-gradient(90deg, #012f50 0%, #012f50 100%);
  --color-gradient-6: linear-gradient(
    180deg,
    rgba(#012f50, 0.6) 0%,
    rgba(#012f50, 0.6) 100%
  );
  --color-gradient-7: linear-gradient(180deg, #012f50 0%, #012f50 100%);
  --color-gradient-8: linear-gradient(
    180deg,
    #6076e8 0%,
    rgba(59, 175, 224, 0.55) 46.47%,
    rgba(23, 232, 216, 0) 100%
  );

  // Background colors
  --background-overlay: rgba(1, 47, 80, 0.95);
  --background-1: rgba(1, 47, 80, 0.9);
  --background-2: rgba(1, 47, 80, 0.94);
  --background-3: rgba(1, 28, 48, 0.2);
  --background-4: rgba(1, 47, 80, 0.8);
  --background-5: #f9fbfc;

  //Plane background
  --plane-nose: url('../../assets/background/plane-nose.svg');
  --left-wing: url('../../assets/background/plane-left-wing-dark.svg');
  --right-wing: url('../../assets/background/plane-right-wing-dark.svg');
  --tail: url('../../assets/background/plane-tail-dark.svg');

  /* stylelint-enable */
}
