$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
$zindex-breadcrumb: 999 !default;

@mixin zindex-dropdown {
  z-index: $zindex-dropdown;
}
@mixin zindex-sticky {
  z-index: $zindex-sticky;
}
@mixin zindex-fixed {
  z-index: $zindex-fixed;
}
@mixin zindex-modal-backdrop {
  z-index: $zindex-modal-backdrop;
}
@mixin zindex-modal {
  z-index: $zindex-popover;
}
@mixin zindex-popover {
  z-index: $zindex-popover;
}
@mixin zindex-tooltip {
  z-index: $zindex-tooltip;
}

@mixin zindex-breadcrumb {
  z-index: $zindex-breadcrumb;
}

/* stylelint-disable */
@mixin elevation-0 {
  box-shadow: none !important;
}
@mixin elevation-1 {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17) !important;
}
@mixin elevation-2 {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.17) !important;
}
@mixin elevation-3 {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.17) !important;
}
@mixin elevation-4 {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12), 0 6px 6px 0 rgba(0, 0, 0, 0.24),
    0 8px 12px 0 rgba(0, 0, 0, 0.17), 0 8px 24px 0 rgba(0, 0, 0, 0.17) !important;
}
@mixin elevation-5 {
  box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.17), 0 8px 20px 0 rgba(0, 0, 0, 0.17),
    0 16px 32px 0 rgba(0, 0, 0, 0.17) !important;
}
@mixin elevation-6 {
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.17);
}

@mixin elevation-7 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
}
@mixin elevation-8 {
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.07);
}

@mixin elevation-8 {
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.13);
}

@mixin elevation-8 {
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.13);
}

.elevation-1 {
  @include elevation-1;
}
.elevation-2 {
  @include elevation-2;
}
.elevation-3 {
  @include elevation-3;
}
.elevation-4 {
  @include elevation-4;
}
.elevation-5 {
  @include elevation-5;
}
.elevation-6 {
  @include elevation-6;
}
.elevation-7 {
  @include elevation-7;
}
.elevation-8 {
  @include elevation-8;
}
