.default-theme {
  //Primary for Backgrounds
  --color-0: #ffffff;
  --color-1: #fafafa;
  --color-2: #F2F2F7;
  --color-3: #e3eaf0;
  --color-4: #52BB0B;
  --color-5: #fafafa;
  --color-6: #008c3c;
  --color-7: #f28500;
  --color-8: #bd081e;
  --color-9: rgba(0, 0, 0, 0.38);
  --color-10: #fff;
  --color-11: #000;
  --color-12: #f5f5f5;
  --color-13: #bdd6f4;
  --color-14: #52BB0B;
  --color-15: #fff;
  --color-16:#EBEBEB;
  --color-17: #F5FBF1;
  --color-18: #E1FFE2;
  --color-19: #000000D9;
  --color-20: #EEF9E6;
  --color-21: #8ABA10;
  --color-22: #E7603C;
  --color-23: #FDF6E0;
  --color-24: #ffedef;

  //Accent Colors
  --accent-1: #bd10e0;
  --accent-2: #52BB0B;

  //Text & Icon Colors
  --text-color-primary: #424242;
  --text-color-secondary: rgba(0, 0, 0, 0.6);
  --text-color-tertiary: #fff;
  --text-color-4: rgba(0, 0, 0, 0.38);
  --text-color-5: rgba(255, 255, 255, 0.38);
  --text-color-success: #5ca137;
  --text-color-warning: #EDA400;
  --text-color-error: #bd081e;
  --text-color-disabled: rgba(0, 0, 0, 0.38);
  --text-color-disabled-2: #898989;
  --text-color-link: #52BB0B;
  --text-color-accent: #bd10e0;
  --text-color-link-2: #52BB0B;
  --text-color-title: #898989;
  --text-color-input: #424242;
  --text-color-title-light : #00000040;
  --text-color-placeholder-2: rgba(0, 0, 0, 0.45);
  --text-color-supporting-text: #00000073;
  --text-color-primary-green-2: #008C3C;
  --text-color-primary-green-3: #008465;


  //Button Colors
  --button-primary: #52BB0B;
  --button-secondary: #F5FBF1;
  --button-tertiary: #fff;
  --button-cancel: #bd081e;
  --button-dark: #012f50;
  --button-disabled: #a7a7a7;
  --button-disabled-2: #8E8E93;
  --button-disabled-3: #00000040;
  --button-toggle-1: rgba(255, 255, 255, 0.2);
  --button-toggle-2: rgba(255, 255, 255, 0.1);
  --button-selected: #fff;
  --button-link-text: #0069b3;
  --button-text-primary: #fff;
  --button-text-secondary: rgba(0, 0, 0, 0.87);
  --button-text-tertiary: rgba(255, 255, 255, 0.87);
 

  //Seats
  --text-normal-seat: #012f50;
  --seat-unavailable: #e3eaf0;
  --seat-default: #012f50;
  --seat-business: #5ca137;
  --seat-legroom: #bd10e0;
  --seat-normal: #0069b3;
  --seat-speedy-exit: #f28500;
  --seat-standard-plus: #000;
  --seat-selected: #52BB0B;
  --seat-preferred: #bdd6f4;

  // Borders
  --border-1: rgba(0, 0, 0, 0.2);
  --border-2: rgba(0, 0, 0, 0.2);
  --border-3: #e3eaf0;
  --border-4: #52BB0B;
  --border-5: #fff;
  --border-6: #bd081e;
  --border-7: #bd10e0;
  --border-8: rgba(255, 255, 255, 0.2);
  --border-9: rgba(0, 0, 0, 0.1);
  --border-10: rgba(0, 0, 0, 0.08);
  --border-11: #D9D9D9;
  --border-12: #51B90B;
  --border-13: #008C3C;
  --border-14: #898989;
  --border-15: #00000026; 
  --border-16: #D9D9D9; 

  // Gradients
  --color-gradient-1: linear-gradient(0deg, #fafafa 0%, #0069b3 100%);
  --color-gradient-2: linear-gradient(90deg, #fafafa 0%, #bd10e0 100%);
  --color-gradient-3: linear-gradient(90deg, #fafafa 0%, #f28500 100%);
  --color-gradient-4: linear-gradient(90deg, #fafafa 0%, #0069b3 100%);
  --color-gradient-5: linear-gradient(90deg, #fafafa 0%, #5ca137 100%);
  --color-gradient-6: linear-gradient(
    180deg,
    rgba(#fafafa, 0.6) 0%,
    rgba(#52BB0B, 0.6) 100%
  );
  --color-gradient-7: linear-gradient(180deg, #52BB0B 0%, #fafafa 100%);
  --color-gradient-8: linear-gradient(
    180deg,
    #6076e8 0%,
    rgba(59, 175, 224, 0.55) 46.47%,
    rgba(23, 232, 216, 0) 100%
  );

  // Background colors
  --background-overlay: rgba(0, 0, 0, 0.75);
  --background-1: rgba(82, 187, 11, 0.9);
  --background-2: rgba(82, 187, 11, 0.94);
  --background-3: rgba(250, 250, 250, 0.2);
  --background-4: rgba(82, 187, 11, 0.8);
  --background-5: #f9fbfc;
  --background-6: #0000000F;
  --background-7: #00000040;
  --background-8: rgba(0, 0, 0, 0.04);
  --background-9: #EEEEEE;
  --background-error: #F7EAE9;

  //Plane background
  --plane-nose: url('../../assets/background/plane-nose.svg');
  --left-wing: url('../../assets/background/plane-left-wing.svg');
  --right-wing: url('../../assets/background/plane-right-wing.svg');
  --tail: url('../../assets/background/plane-tail.svg');

  //Calendar colors
  --sun-text: #E7603C;
  --weekdays-text: #8C8C8C;
  --days-highlight: #EEF9E6;

  /* stylelint-enable */
}
