/* stylelint-disable */

// Padding
@use "sass:math";

$p1: 0.25rem;
$p2: 0.5rem;
$p3: 1rem;
$p4: 1.5rem;
$p5: 3rem;

// Margin
$m1: 0.25rem;
$m2: 0.5rem;
$m3: 1rem;
$m4: 1.5rem;
$m5: 3rem;

/* stylelint-enable */

.accessibility-hidden {
  display: none !important;
}

@mixin xl-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin lg-down {
  @media (max-width: 1199.98px) {
    @content;
  }
}

@mixin lg-up {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin md-down {
  @media (max-width: 991.98px) {
    @content;
  }
}

@mixin md-only {
  @media (min-width: 768px) and (max-width: 991.98px) {
    @content;
  }
}

@mixin md-up {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin sm-down {
  @media (max-width: 767.98px) {
    @content;
  }
}

@mixin sm-up {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin xs-down {
  @media (max-width: 575.98px) {
    @content;
  }
}

@mixin xs-up {
  @media (min-width: 321px) {
    @content;
  }
}

@mixin tiny-down {
  @media (max-width: 320.98px) {
    @content;
  }
}

// Columns
@mixin col1 {
  width: percentage(math.div(1, 12));
}
@mixin col2 {
  width: percentage(math.div(1, 6));
}
@mixin col3 {
  width: percentage(1 * 0.25);
}
@mixin col4 {
  width: percentage(math.div(1, 3));
}
@mixin col5 {
  width: percentage(math.div(5, 12));
}
@mixin col6 {
  width: percentage(1 * 0.5);
}
@mixin col7 {
  width: percentage(math.div(7, 12));
}
@mixin col8 {
  width: percentage(math.div(2, 3));
}
@mixin col9 {
  width: percentage(3 * 0.25);
}
@mixin col10 {
  width: percentage(math.div(5, 6));
}
@mixin col11 {
  width: percentage(math.div(11, 12));
}
@mixin col12 {
  width: 100%;
}

@mixin row-nogutter {
  /* stylelint-disable */
  display: -ms-flexbox;
  /* stylelint-enable */
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@mixin row {
  @include row-nogutter();
  margin-left: -15px;
  margin-right: -15px;
}

@mixin container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

@mixin container {
  @include container-fluid;
  @include sm-up {
    max-width: 540px;
  }
  @include md-up {
    max-width: 720px;
  }
  @include lg-up {
    max-width: 960px;
  }
  @include xl-up {
    max-width: 1140px;
  }
}

.row {
  @include row;
}

// Containers
.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.container {
  @include container;
}

.mobile {
  @include md-up {
    display: none !important;
  }
}

.not-mobile {
  @include sm-down {
    display: none !important;
  }
}

.full-width {
  width: 100%;
}
